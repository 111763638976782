<template>
  <div
    id="Cop26"
    class="d-flex justify-center flex-column align-center mt-2"
  >
    <base-info-card
      class="pl-4"
      :title="$t('cop26.title')"
      :title-img="imgLogo"
      size="text-h5"
      pace="6"
    />
    <v-data-table
      disable-sort
      hide-default-footer
      :headers="headers"
      :items="list"
      :item-class="rowClass"
      :search="search"
    >
      <!-- <template #[`header.day`]="{ header }">
        {{ $t(header.i18n) }}
      </template>
      <template #[`header.event`]="{ header }">
        {{ $t(header.i18n) }}
      </template> -->
      <template #[`item.day`]="{ item }">
        {{ $t(item.day) }}
      </template>
      <template #[`item.event`]="{ item }">
        {{ $t(item.text) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    name: 'SectionCop26',
    data: () => ({
      search: '',
      imgLogo: require('@/assets/zcp/china-cop.png'),
      headers: [
        {
          text: 'COP DAY',
          i18n: 'cop26.title1',
          align: 'center',
          width: '30%',
          value: 'day',
        },
        {
          text: 'PODCAST THEME',
          i18n: 'cop26.title2',
          align: 'center',
          width: '70%',
          value: 'event',
        },
      ],
    }),
    computed: {
      list() {
        const list = []
        for (let i = 1; i <= 12; i++) {
          list.push({
            day: `cop26.day${i}`,
            text: `cop26.event${i}`,
            class: `tr${i}`,
          })
        }
        return list
      },
    },
    watch: {
      '$i18n.locale': function (val) {
        this.setTitle()
      },
      '$vuetify.breakpoint.name': {
        deep: true,
        immediate: true,
        handler(val) {
          if (val === 'xs') {
            this.$nextTick(() => {
              document.querySelector('#Cop26 th').style.display = 'none'
            })
          }
        },
      },
    },
    mounted() {
      this.setTitle()
    },
    methods: {
      rowClass(item) {
        return item.class
      },
      setTitle() {
        this.headers[0].text = this.$t('cop26.title1')
        this.headers[1].text = this.$t('cop26.title2')
      },
    },
  }
</script>

<style lang="sass" scoped>
#Cop26
  padding: 10px
  position: relative
  ::v-deep .v-data-table
    width: 90%
    .v-data-table__mobile-row__header
      min-width: 72px
    .tr1
      background-color: #FFEBEE
    .tr2
      background-color: #FCE4EC
    .tr3
      background-color: #F3E5F5
    .tr4
      background-color: #E3F2FD
    .tr5
      background-color: #E0F2F1
    .tr6
      background-color: #E8F5E9
    .tr7
      background-color: #F9FBE7
    .tr8
      background-color: #FFFDE7
    .tr9
      background-color: #FFF3E0
    .tr10
      background-color: #EFEBE9
    .tr11
      background-color: #ECEFF1
</style>
